import illustrationPath from '../../assets/imgs/reset-password.svg';
import classes from './reset-password.module.scss';
import { Alert, Button, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Wrapper } from '../../components/shared/wrapper.component';
import { RiLockPasswordFill, RiLockPasswordLine } from 'react-icons/ri';
import useForgotPassword from '../../Hooks/forgot-password.hook';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { Constants } from '../../constants/constants';
import useDocumentTitle from '../../Hooks/document-title.hook';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ResetPassword = () => {
  const { resetPasswordByToken, state } = useForgotPassword();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';
  const userId = searchParams.get('userId') ?? '';
  const [form] = useForm();
  useDocumentTitle('Reset Password')

  const resetPassword = async () => {
    await resetPasswordByToken({
      token: decodeURIComponent(token),
      userId: decodeURIComponent(userId),
      password: form.getFieldValue('password'),
    });
  };

  const resetPasswordForm = (
    <div className={classes.resetPasswordContainer}>
      <div className={classes.textContainer}>
        <h1 className={classes.title}>New Password Creation</h1>
        <p className={classes.text}>
          Please create a new password to continue.
        </p>
      </div>
      <Form
        size="large"
        form={form}
        layout="vertical"
        name="reset-password"
        onFinish={resetPassword}
        className={classes.resetPasswordForm}
        scrollToFirstError
        disabled={state.loading}
        requiredMark={false}
      >
        <Form.Item
          className={classes.formItem}
          name="password"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            {
              pattern: Constants.PASSWORD_PATTERN,
              message: Constants.PASSWORD_VALIDATION_MESSAGE,
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<RiLockPasswordLine />}
            placeholder="Password"
            size="large"
            className={classes.inputField}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords do not match'),
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<RiLockPasswordFill />}
            placeholder="Confirm password"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className={classes.resetPasswordBtn}
            disabled={state.loading}
            type="primary"
            htmlType="submit"
          >
            Update my password
          </Button>
          {state.loading && (
            <Spin indicator={antIcon} className={classes.feedback} />
          )}
        </Form.Item>
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                  return <p key={id}>{msg}</p>;
                })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
    </div>
  );

  return (
    <Wrapper
      form={resetPasswordForm}
      illustration={{ path: illustrationPath, name: 'reset-password' }}
      alignIllustration="left"
    />
  );
};

export default ResetPassword;
