import { throwHttpError } from './errors';
import accessTokenService from './access-token.service';

type IMethod = 'GET' | 'POST' | 'PATCH';

class UserManagementService {
  API: string;

  constructor() {
    this.API = process.env.REACT_APP_PUBLIC_USER_ENDPOINT || '//sso-dev.sadasol.com/api/user';
  }

  private getOptions(method: IMethod = 'GET', body?: any, formData?: FormData): RequestInit {
    const accessToken = accessTokenService.get();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    };

    const options: RequestInit = { method, headers, body: JSON.stringify(body) };

    return options;
  }

  private async fetchAPI(path: string, searchParams: { key: string, value?: string | number }[], options?: RequestInit) {
    const url = this.generateURL(path, searchParams);
    return fetch(url.toString(), options || this.getOptions())
      .then(throwHttpError)
      .then(response => response.json());
  }

  private generateURL(path: string, searchParams?: { key: string, value?: string | number }[]) {
    const url = new URL('/api/user' + path, this.API);

    searchParams?.forEach(searchParam => {
      if (searchParam.value !== undefined) {
        url.searchParams.set(searchParam.key, searchParam.value.toString());
      }
    });

    return url;
  }

  async getAllUsers(props: { page: number, pageSize?: number, searchTerms?: string, signal: AbortSignal }) {
    const path = '/users';
    const searchParams = [
      { key: 'page', value: props.page },
      { key: 'pageSize', value: props.pageSize },
      { key: 'searchTerms', value: props.searchTerms }
    ]

    return this.fetchAPI(path, searchParams)
      .then(response => ({
        data: {
          users: response.users.map(
            (user: IUserInfo & { _id: string }) => ({ ...user, id: user._id })
          ) as IUserInfo[],
          total: response.count
        },
        error: undefined
      }))
      .catch(error => {
        console.error('error: ', error);
        return {
          error: {
            message: 'Something went wrong.',
            details: error
          },
          data: undefined
        }
      });
  }

  async verifyUser(userId: string) {
    const path = '/verify';
    const searchParams = [
      { key: 'userId', value: userId }
    ]

    return this.fetchAPI(path, searchParams, this.getOptions('PATCH'));
  }

  async removeUserDisabledStatus(userId: string) {
    const path = '/remove-disabled';
    const searchParams = [
      { key: 'userId', value: userId }
    ]

    return this.fetchAPI(path, searchParams, this.getOptions('PATCH'))
  }

  async setExpiryDate(userId: string, expiryDate: Date) {
    const path = '/expire';
    const searchParams = [
      { key: 'userId', value: userId },
      { key: 'expiresAt', value: expiryDate.toISOString() }
    ]

    return this.fetchAPI(path, searchParams, this.getOptions('PATCH'));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserManagementService();
