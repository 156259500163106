import { useState } from 'react';
import { FormProps } from 'antd/es/form/Form';
import profileService from '../../Services/profile.service';
import { Notification } from '../../utils';
import { useProfileContext } from '../../contexts/profile.context';

const useProfile = () => {
  const { userInfo } = useProfileContext();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit: FormProps<IUserInfo>['onFinish'] = async (values: IProfileFormValues) => {
    setLoading(true);
    profileService.updateUserInfo(values)
      .then(res => {
        if (res.type === 'error') {
          Notification.error({ description: res.result.message });
          return;
        }

        Notification.success({ description: res.result.message })
      })
      .catch(error => {
        Notification.error()
        console.error(error)
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleSubmitFail: FormProps<IUserInfo>['onFinishFailed'] = () => {
    Notification.error({ description: 'Please fill all the empty fields' });
  }

  return {
    userInfo,
    isLoading,
    handleSubmit,
    handleSubmitFail
  };
};

export default useProfile;
