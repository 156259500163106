import { useState } from 'react';
import { FormProps } from 'antd';
import { Notification } from '../../utils';
import passwordService from '../../Services/password.service';
import accessTokenService from '../../Services/access-token.service';
import { useNavigate } from 'react-router-dom';

const useUpdatePassword = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit: FormProps<IPasswordFormValues>['onFinish'] = (values: IPasswordFormValues) => {
    setLoading(true);

    passwordService
      .resetPassword({ password: values.newPassword, oldPassword: values.currPassword, })
      .then((res) => {
        if (res.type === 'success') {
          accessTokenService.remove();
          const message = encodeURIComponent("Your password has been reset successfully.")
          const loginRoute = `/login?msg=${message}&error=false`;
          navigate(loginRoute);
          return;
        } else {
          Notification.error({ description: 'An error occurred while updating your password' })
        }
      })
      .catch(error => {
        Notification.error({ description: error.message })
        console.error(error)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitFail: FormProps<IPasswordFormValues>['onFinishFailed'] = () => {
    Notification.error({ description: 'Please fill all the empty fields' });
  }

  return {
    isLoading,
    handleSubmit,
    handleSubmitFail
  };
};

export default useUpdatePassword;
