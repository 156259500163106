import dayjs from "dayjs";
import { Notification } from "../../../../../../utils";
import { userManagementService } from "../../../../../../Services";
import { useUserManagement } from "../../../../../../Hooks";

import { Button, Space, TableColumnsType, Tag, Tooltip } from "antd";
import { FaUserCheck } from "react-icons/fa";
import { MdTimer, MdTimerOff } from "react-icons/md";

export function getUsersTableColumns(userManagement: ReturnType<typeof useUserManagement>)
  : TableColumnsType<IUserInfo> {
  const verifyHandler = async (userId: string) => {
    userManagementService.verifyUser(userId)
      .then(() => {
        Notification.success({ description: 'User verified successfully.' });
        userManagement.fetchUsers();
      })
      .catch(() => (
        Notification.error({ description: 'Something went wrong.' })
      ));
  }

  const toggleUserDisabledStatus = async (userId: string) => {
    userManagementService.removeUserDisabledStatus(userId)
      .then(() => {
        Notification.success({ description: 'Expiry date removed successfully.' });
        userManagement.fetchUsers();
      })
      .catch(() => (
        Notification.error({ description: 'Something went wrong.' })
      ));
  }

  return ([
    {
      title: 'Full name',
      dataIndex: 'firstName',
      render: (_: any, record: IUserInfo) => `${record.firstName} ${record.lastName}`,
      width: '20%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (role: string) => <Tag color={role === 'ADMIN' ? 'error' : 'default'}>{role}</Tag>,
      width: '10%'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <Tag color={status === 'ACTIVE' ? 'success' : 'default'}>{status}</Tag>,
      width: '10%'
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (_: any, record: IUserInfo) => record.createdAt ? dayjs(record.createdAt).format('YYYY/MM/DD  HH:mm') : '-',
      width: '15%'
    },
    {
      title: 'Expire date',
      dataIndex: 'expiresAt',
      render: (_: any, record: IUserInfo) => {
        const isExpired = record.expiresAt && (new Date(record.expiresAt).getTime() < (new Date()).getTime());

        return (
          record.expiresAt
            ? <Space title={dayjs(record.expiresAt).format('YYYY/MM/DD HH:mm')}>
              {dayjs(record.expiresAt).format('YYYY/MM/DD')}
              {isExpired && <Tag color="error">Expired</Tag>}
            </Space>
            : '-'
        )
      },
      width: '15%'
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_: any, record: IUserInfo) => {
        const expiresAt = record.expiresAt;
        const isVerified = record.status === 'ACTIVE';

        return (
          <Space>
            <Tooltip
              title={expiresAt ? 'Remove expire date' : 'Set expire date'}
            >
              <Button
                type="text"
                icon={
                  expiresAt
                    ? <MdTimerOff size={18} />
                    : <MdTimer size={18} />
                }
                onClick={() => expiresAt ? toggleUserDisabledStatus(record.id) : userManagement.setExpiryDateModalUserId(record.id)}
              />
            </Tooltip>
            <Tooltip
              title="Verify user"
            >
              <Button
                type="text"
                icon={<FaUserCheck size={18} />}
                disabled={isVerified}
                onClick={() => verifyHandler(record.id)}
              />
            </Tooltip>
          </Space>
        )
      },
    }
  ])
}