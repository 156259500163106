import classes from './expire-date-modal.module.scss';
import { userManagementService } from '../../../../../../Services';
import { Notification } from '../../../../../../utils';
import { useUserManagement } from '../../../../../../Hooks';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useProfileContext } from '../../../../../../contexts/profile.context';

import { RiTimerLine } from 'react-icons/ri';
import { DatePicker, Form, Modal } from "antd";

interface IForm {
  expireDate: Date;
}

interface IProps {
  expiryDateModalUserId?: string;
  userManagement: ReturnType<typeof useUserManagement>;
}

export default function ExpireDateModal(props: IProps) {
  const [form] = Form.useForm<IForm>();
  const expireDate = useWatch(['expireDate'], form);
  const profileCtx = useProfileContext();

  const submitHandler = async () => {
    try {
      const userId = profileCtx.userInfo.value?.id;

      if (!userId || !props.expiryDateModalUserId) {
        return;
      }

      const values = await form.validateFields();
      props.userManagement.setExpiryDateModalUserId(props.expiryDateModalUserId);
      await userManagementService.setExpiryDate(props.expiryDateModalUserId, values.expireDate);
      Notification.success({ description: 'Expiry date was updated successfully.' });
    } catch (error) {
      Notification.error({ description: 'Something went wrong while setting expiry date.' })
    }

    try {
      props.userManagement.fetchUsers();
    } catch {
      Notification.error({ description: 'Something went wrong while fetching users data.' })
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [props.expiryDateModalUserId])

  return (
    <Modal
      title={<>
        <p>Setting user expiry date</p>
        <p>Set a date when this user account will be automatically.</p>
      </>}
      className={classes.modal}
      open={!!props.expiryDateModalUserId}
      onOk={submitHandler}
      okButtonProps={{ disabled: !expireDate }}
      okText={<span><RiTimerLine size={18} /> Set expiry date</span>}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={() => props.userManagement.setExpiryDateModalUserId()}
    >
      <Form<IForm>
        name="dateExpiryForm"
        autoComplete="off"
        disabled={false}
        requiredMark={false}
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="expireDate"
          label="Expire date"
          rules={[{ required: true, message: 'Please enter the expire date!' }]}
        >
          <DatePicker showTime />
        </Form.Item>
      </Form>
    </Modal>
  )
}