import classes from './update-password-section.module.scss';
import { Constants } from '../../../../../constants/constants';
import useUpdatePassword from '../../../../../Hooks/settings/update-password.hook';

import { Input, Button, Form, Spin } from 'antd';

export default function UpdatePasswordSection() {
  const updatePassword = useUpdatePassword();

  return (
    <Spin spinning={updatePassword.isLoading}>
      <Form
        name="updatePassword"
        className={classes.form}
        initialValues={{ remember: true }}
        onFinish={updatePassword.handleSubmit}
        onFinishFailed={updatePassword.handleSubmitFail}
        disabled={updatePassword.isLoading}
        layout='vertical'
      >
        <Form.Item
          label="Current Password"
          name="currPassword"
          rules={[{ required: true, message: 'Please enter your current password' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'Please enter your new password' },
            { pattern: Constants.PASSWORD_PATTERN, message: Constants.PASSWORD_VALIDATION_MESSAGE }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm new Password"
          name="confirm"
          dependencies={['newPassword']}
          hasFeedback
          rules={[{ required: true, message: 'Please confirm your new password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error('The two passwords do not match'),
              );
            },
          }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className={classes.formItem}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={updatePassword.isLoading}
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
