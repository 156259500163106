import { useEffect, useRef, useState } from 'react';
import { Notification } from '../../utils';
import userManagementService from '../../Services/user-management.service';
import useDebounce from '../debounce.hook';
import { useSearchParams } from 'react-router-dom';

export type IPageSize = 5 | 10 | 15 | 20;

interface IState {
  users: IUserInfo[];
  currentPage: number;
  pageSize: IPageSize;
  total: number;
  searchTerms: string;
  isLoading: boolean;
  expiryDateModalUserId?: string;
}

const DEFAULT_PAGE_SIZE = 5;
const DEBOUNCE_DELAY = 400;
const PAGE_SIZE_STORAGE_KEY = 'user-management-page-size';
const SEARCH_PARAM_KEY = 'searchTerms';

const useUserManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IState>({
    users: [],
    pageSize: getInitPageSize(),
    searchTerms: searchParams.get(SEARCH_PARAM_KEY) || '',
    currentPage: 1,
    total: 0,
    isLoading: false
  });
  const debouncedSearchTerms = useDebounce(state.searchTerms, DEBOUNCE_DELAY);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const updateState = (newState: Partial<IState>) => {
    setState(prev => ({ ...prev, ...newState }))
  };

  function getInitPageSize(): IPageSize {
    const stored = localStorage.getItem(PAGE_SIZE_STORAGE_KEY);

    if (!stored) {
      return DEFAULT_PAGE_SIZE
    };

    return parseInt(stored) as IPageSize;
  };

  const setExpiryDateModalUserId = (userId?: string) => (
    updateState({ expiryDateModalUserId: !state.expiryDateModalUserId ? userId : undefined })
  )

  const createPagesInfoLabel = () => {
    const { currentPage, pageSize, total } = state;

    const from = total ? (pageSize * (currentPage - 1)) + 1 : 0;
    const to = Math.min(pageSize * currentPage, total);

    return `${from}-${to} of ${total}`;
  };

  const nextPage = () => updateState({ currentPage: state.currentPage + 1 })
  const prevPage = () => updateState({ currentPage: state.currentPage - 1 })

  const updatePageSizeHandler = (pageSize: IPageSize) => {
    updateState({ pageSize })
    localStorage.setItem(PAGE_SIZE_STORAGE_KEY, JSON.stringify(pageSize));
  }

  const updateSearchParams = (params: { search?: string; }) => {
    const newParams = new URLSearchParams('');

    if (params.search) {
      newParams.set(SEARCH_PARAM_KEY, params.search);
    } else {
      newParams.delete(SEARCH_PARAM_KEY);
    }

    setSearchParams(newParams, { replace: true });
  };

  const fetchUsers = (
    page = state.currentPage - 1,
    pageSize = state.pageSize,
    searchTerms = state.searchTerms
  ) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    updateState({ isLoading: true })

    userManagementService
      .getAllUsers({ page, pageSize, signal, searchTerms })
      .then(response => {
        if (response.error && !signal.aborted) {
          Notification.error({ description: response.error.message });
          return;
        }

        updateState({ ...response.data })
      })
      .finally(() => {
        if (!signal.aborted) {
          updateState({ isLoading: false })
        }
      });
  }

  useEffect(() => {
    fetchUsers();

    return () => abortControllerRef.current.abort();
  }, [state.currentPage, state.pageSize, debouncedSearchTerms])

  useEffect(() => {
    updateSearchParams({ search: state.searchTerms })
  }, [state.searchTerms]);

  return {
    state,
    updateState,
    nextPage,
    prevPage,
    fetchUsers,
    setExpiryDateModalUserId,
    updatePageSizeHandler,
    createPagesInfoLabel
  };
};

export default useUserManagement;
