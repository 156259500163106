import classes from './users-table.module.scss';
import { getUsersTableColumns } from './table-data';
import { useUserManagement } from '../../../../../../Hooks';

import { Table } from "antd";
import { TableActions, TableFooter } from '.';

interface IProps {
  userManagement: ReturnType<typeof useUserManagement>;
}

export default function UsersTable({ userManagement }: IProps) {

  return (<>
    <TableActions userManagement={userManagement} />
    <Table<IUserInfo>
      className={classes.table}
      rowKey="id"
      columns={getUsersTableColumns(userManagement)}
      dataSource={userManagement.state.users}
      pagination={false}
      onRow={() => ({
        style: { height: '48px' }
      })}
      summary={() => <TableFooter userManagement={userManagement} />}
    />
  </>)
}