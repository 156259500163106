import classes from './profile-section.module.scss';
import useProfile from '../../../../../Hooks/settings/profile.hook';

import Avatar from 'react-avatar';
import { Form, Input, Button, Spin, Skeleton } from 'antd';

export default function ProfileSection() {
  const profile = useProfile();
  const userInfo = profile.userInfo.value;

  const fullName = userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : '';
  const requiredRule = [{ required: true, message: 'This field cannot be empty' }]
  const initialFields = Object.entries(profile.userInfo.value || {}).map(([key, value]) => ({
    name: key,
    value,
  }));

  return (
    <Spin spinning={profile.isLoading}>
      <Form
        name="profile"
        className={classes.form}
        fields={initialFields}
        onFinish={profile.handleSubmit}
        onFinishFailed={profile.handleSubmitFail}
        disabled={profile.isLoading}
        layout='vertical'
      >
        <div className={classes.info}>
          <div className={classes.fields}>
            <Form.Item
              label="First Name"
              name="firstName"
            rules={requiredRule}
            >
              <Input value={userInfo?.firstName} onChange={(e) => userInfo && profile.userInfo.set({ ...userInfo, firstName: e.target.value })} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={requiredRule}
            >
              <Input value={userInfo?.lastName} onChange={(e) => userInfo && profile.userInfo.set({ ...userInfo, lastName: e.target.value })} />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input disabled />
            </Form.Item>
          </div>
          {profile.isLoading || !userInfo
            ? <Skeleton.Avatar active={true} size={150} shape="circle" />
            : (
              <Avatar
                size={150}
                name={fullName}
                round
                color="rgb(99, 107, 116)"
              />
            )}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
