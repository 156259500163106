import useProfile from '../../Hooks/settings/profile.hook';

import { Outlet, Navigate } from 'react-router-dom';

export const AdminProtectedRoute = () => {
  const profile = useProfile();
  const isAdmin = profile.userInfo.value?.role === 'ADMIN';

  if (profile.isLoading) {
    return null;
  }

  if (!isAdmin) {
    const redirectTo = `/settings/profile`;
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};
