import { useUserManagement } from '../../../../../Hooks';

import { Spin } from 'antd';
import UsersTable from './users-table/users-table.component';
import ExpireDateModal from './expire-date-modal/expire-date-modal.component';

export default function UserManagementSection() {
  const userManagement = useUserManagement();

  return (
    <Spin spinning={userManagement.state.isLoading}>
      <ExpireDateModal userManagement={userManagement} expiryDateModalUserId={userManagement.state.expiryDateModalUserId} />
      <UsersTable userManagement={userManagement} />
    </Spin>
  );
};
