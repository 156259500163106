import classes from './table-footer.module.scss'

import { IPageSize } from "../../../../../../../Hooks/settings/user-management";
import { useUserManagement } from "../../../../../../../Hooks";

import { Button, Select, Space, Table, Typography } from "antd";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { HiChevronUpDown } from "react-icons/hi2";

interface IProps {
  userManagement: ReturnType<typeof useUserManagement>;
}

export default function TableFooter({ userManagement }: IProps) {
  const { currentPage, pageSize, total } = userManagement.state;

  return (
    <Table.Summary fixed="bottom">
      <Table.Summary.Row>
        <Table.Summary.Cell className={classes.footer} index={0} colSpan={7}>
          <Space size="large">
            <Space size="small">
              <Typography.Text>Rows per page:</Typography.Text>
              <Select<IPageSize>
                defaultValue={5}
                value={userManagement.state.pageSize}
                style={{ width: 53 }}
                onChange={userManagement.updatePageSizeHandler}
                suffixIcon={<HiChevronUpDown size={17} color='rgb(100, 100, 100)' />}
                options={[
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 20, label: '20' }
                ]}
              />
            </Space>
            <Typography.Text>{userManagement.createPagesInfoLabel()}</Typography.Text>
            <Space size="small">
              <Button
                icon={<IoChevronBackOutline />}
                onClick={userManagement.prevPage}
                disabled={currentPage === 1}
              />
              <Button
                icon={<IoChevronForwardOutline />}
                onClick={userManagement.nextPage}
                disabled={currentPage >= Math.ceil(total / pageSize)}
              />
            </Space>
          </Space>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  )
}