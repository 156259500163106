import classes from './settings.module.scss';
import { classNames } from '../../utils';
import useWindowDimensions from '../../Hooks/window-dimensions.hook';
import useDocumentTitle from '../../Hooks/document-title.hook';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import useProfile from '../../Hooks/settings/profile.hook';
import { useEffect, useState } from 'react';

import { Breadcrumb, Menu, MenuProps } from 'antd';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdChevronRight } from 'react-icons/md';
import { AiFillHome } from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { IoPersonSharp } from 'react-icons/io5';

type MenuItem = Required<MenuProps>['items'][number];
type ISection = 'profile' | 'update-password' | 'user-management';
interface ISectionDetails {
  title: string;
  isFullWidth?: boolean;
}

const DEFAULT_SECTION_PATH = 'profile';

const ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: 'ADMIN SETTINGS',
    key: 'admin-settings',
    type: 'group',
    children: [
      {
        label: 'Users management',
        key: 'user-management',
        icon: <FaUserFriends />
      }
    ]
  }
]

const MENU_ITEMS: MenuItem[] = [
  {
    label: 'ACCOUNT SETTINGS',
    key: 'account-settings',
    type: 'group',
    children: [
      {
        label: 'Profile',
        key: 'profile',
        icon: <IoPersonSharp />
      },
      {
        label: 'Update password',
        key: 'update-password',
        icon: <RiLockPasswordFill />
      }
    ]
  }
]

const SECTION_DETAILS: Record<ISection, ISectionDetails> = {
  'profile': { title: 'Profile' },
  'update-password': { title: 'Update Password' },
  'user-management': { title: 'User Management', isFullWidth: true }
}

export const Settings = () => {
  useDocumentTitle('My Profile');
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [currentSection, setCurrentSection] = useState<ISection>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const sectionSegment = pathSegments?.[2]

    if (!!sectionSegment && Object.keys(SECTION_DETAILS).includes(sectionSegment)) {
      setCurrentSection(location.pathname.split('/')[2] as ISection)
    } else {
      console.log('navigate');
      navigate(`/settings/${DEFAULT_SECTION_PATH}`);
    }
  }, [location]);

  if (!currentSection) {
    return null;
  }

  const currentSectionDetails = SECTION_DETAILS[currentSection];

  return (
    <div className={classes.wrapper}>
      <Menu
        onClick={e => navigate(`/settings/${e.key}`)}
        mode="vertical"
        selectedKeys={[currentSection]}
        inlineCollapsed={width <= 1024}
        items={profile.userInfo.value?.role === 'ADMIN' ? [...MENU_ITEMS, ...ADMIN_MENU_ITEMS] : MENU_ITEMS}
      />
      <main className={classNames(classes.main, { [classes.full]: currentSectionDetails.isFullWidth })}>
        <div className={classes['page-info']}>
          <Breadcrumb
            separator={<MdChevronRight size={16} />}
            items={[
              {
                title: <AiFillHome />,
                href: '/'
              },
              {
                title: 'Settings'
              },
              {
                title: currentSectionDetails.title
              }
            ]}
          />
          <h1>{currentSectionDetails.title}</h1>
        </div>
        <Outlet />
      </main>
    </div>
  );
};
