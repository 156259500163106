import illustrationPath from '../../assets/imgs/register.svg';
import classes from './register.module.scss';
import { Alert, Button, Checkbox, Form, Input, Typography } from 'antd';
import useRegister from '../../Hooks/register.hook';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Wrapper } from '../../components/shared/wrapper.component';
import { BsFillPersonLinesFill, BsPersonCircle } from 'react-icons/bs';
import { MdMail } from 'react-icons/md';
import { RiLockPasswordFill, RiLockPasswordLine } from 'react-icons/ri';
import { Constants } from '../../constants/constants';
import TermsOfServicesModal from '../../components/terms-of-services-modal/terms-of-services.component';

const RegisterPage = () => {
  const { register, onVerify, form, state, refreshReCaptcha } = useRegister();

  const registerForm = (
    <div className={classes.registerContainer}>
      <div className={classes.textContainer}>
        <h1 className={classes.title}>Create an account</h1>
        <p className={classes.text}>Let's go through a few simple steps</p>
      </div>
      <Form
        size="large"
        layout="vertical"
        form={form}
        name="register"
        onFinish={register}
        className={classes.registerForm}
        scrollToFirstError
        disabled={state.loading}
        requiredMark={false}
      >
        <Form.Item
          className={classes.formItem}
          name="firstName"
          rules={[
            {
              required: true,
              message: 'This field is required',
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<BsPersonCircle color="grey" />}
            placeholder="First Name"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="lastName"
          rules={[
            {
              required: true,
              message: 'This field is required',
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<BsFillPersonLinesFill color="grey" />}
            placeholder="Last Name"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Invalid Email',
            },
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input
            prefix={<MdMail color="grey" />}
            placeholder="Email"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="password"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            {
              pattern: Constants.PASSWORD_PATTERN,
              message: Constants.PASSWORD_VALIDATION_MESSAGE,
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<RiLockPasswordLine color="grey" />}
            placeholder="Password"
            size="large"
            className={classes.inputField}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          className={classes.formItem}
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords do not match'),
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<RiLockPasswordFill color="grey" />}
            placeholder="Confirm password"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <GoogleReCaptcha
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
        <Form.Item
          className={classes.formItem}
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error('You should accept the agreement'),
                  ),
            },
          ]}
        >
          <Checkbox>
            <span>I accept the</span>
            &nbsp;
            <span>
              <TermsOfServicesModal />
            </span>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            className={classes.signUpBtn}
            loading={state.loading}
            type="primary"
            htmlType="submit"
          >
            Sign Up
          </Button>
          <p className={classes.loginText}>
            Already have an account?{' '}
            <Typography.Link href={`/login?dest=${state.dest}`}>Login</Typography.Link>
          </p>
        </Form.Item>
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                return <p key={id}>{msg}</p>;
              })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
    </div>
  );

  return (
    <Wrapper
      form={registerForm}
      illustration={{ path: illustrationPath, name: 'register' }}
    />
  );
};

export default RegisterPage;
