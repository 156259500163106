import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

const cookies = new Cookies();

class AccessTokenService {
  permittedDomain: string;

  constructor() {
    this.permittedDomain = process.env.REACT_APP_PUBLIC_PERMITTED_DOMAIN || '';
  }
  get = () => {
    let accessToken = cookies.get('accessToken');

    if (accessToken) {
      const data: { exp: number; sub: any } = jwtDecode(accessToken);
      const exp = data.exp;

      if (exp < (new Date().getTime() + 1) / 1000) {
        this.remove();
        accessToken = '';
      }
    }

    return accessToken;
  };

  set = (accessToken: string, rememberMe: boolean = false) => {
    if (rememberMe) {
      const accessTokenData: { exp: number } = jwtDecode(accessToken);
      const accessTokenExp = accessTokenData.exp;

      cookies.set('accessToken', accessToken, {
        path: '/',
        domain: this.permittedDomain,
        expires: new Date(accessTokenExp * 1000),
      });
    } else {
      cookies.set('accessToken', accessToken, {
        path: '/',
        domain: this.permittedDomain,
      });
    }
  };

  remove = () => {
    cookies.remove('accessToken', {
      path: '/',
      domain: this.permittedDomain,
    });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AccessTokenService();
