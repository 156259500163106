import { useEffect, useState } from 'react';
import accessTokenService from '../Services/access-token.service';
import passwordService from '../Services/password.service';
import useDocumentTitle from './document-title.hook';

const homePage = process.env.REACT_APP_PUBLIC_HOME_PAGE || '';

interface IState {
  loading: boolean;
  type: string;
  result?: { message: string[]; response?: string };
}

const useForgotPassword = () => {
  const [state, setState] = useState<IState>({
    loading: false,
    type: '',
  });
  useDocumentTitle('Forgot Password')

  useEffect(() => {
    const accessToken = accessTokenService.get();

    if (accessToken) {
      window.location.href = homePage;
    }
  }, []);

  const requestResetPassword = (values: IRequestResetPassword) => {
    setState({ ...state, loading: true });
    passwordService.requestResetPassword(values).then((data) => {
      setState((prevState) => ({
        ...prevState,
        ...data,
        loading: false,
      }));
    });
  };

  const resetPasswordByToken = async (values: IResetPasswordByToken) => {
    setState({ ...state, loading: true });
    await passwordService.resetPasswordByToken(values).then((data) => {
      setState((prevState) => ({
        ...prevState,
        ...data,
        loading: false,
      }));
      if (data.type === 'success') {
        const loginRoute = `/login?msg=Your password has been reset successfully.&error=false`;
        window.location.href = loginRoute;
      }
    });
  };

  return { requestResetPassword, resetPasswordByToken, state };
};

export default useForgotPassword;
