import classes from './table-actions.module.scss';
import { useUserManagement } from '../../../../../../../Hooks';

import { Button, Input, Space } from 'antd';
import { IoMdRefresh, IoMdSearch } from "react-icons/io";

interface IProps {
  userManagement: ReturnType<typeof useUserManagement>;
}

export default function TableActions({ userManagement }: IProps) {
  const searchHandler = (searchTerms: string) => (
    userManagement.updateState({ searchTerms })
  )

  const refreshHandler = () => (
    userManagement.fetchUsers()
  )

  return (
    <div className={classes.wrapper}>
      <Input
        value={userManagement.state.searchTerms}
        onChange={e => searchHandler(e.target.value)}
        prefix={<IoMdSearch color='rgb(90, 90, 90)' size={21} />}
        placeholder='Search'
      />
      <Space>
        <Button
          onClick={refreshHandler}
          icon={<IoMdRefresh size={19} />}
        >
          Refresh
        </Button>
      </Space>
    </div>
  )
}