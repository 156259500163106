import illustrationPath from '../../assets/imgs/forgot-password.svg';
import classes from './forgot-password.module.scss';
import { Alert, Button, Form, Input, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Wrapper } from '../../components/shared/wrapper.component';
import { MdMail } from 'react-icons/md';
import { IoArrowBackOutline } from 'react-icons/io5';
import useForgotPassword from '../../Hooks/forgot-password.hook';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ForgotPassword = () => {
  const { requestResetPassword, state } = useForgotPassword();

  const forgotPasswordForm = (
    <div className={classes.forgotPasswordContainer}>
      <div className={classes.textContainer}>
        <h1 className={classes.title}>Forgot Password?</h1>
        <p className={classes.text}>No worries, we'll send you a reset link.</p>
      </div>
      <Form
        size="large"
        layout="vertical"
        name="forgot-password"
        onFinish={requestResetPassword}
        className={classes.forgotPasswordForm}
        scrollToFirstError
        disabled={state.loading}
        requiredMark={false}
      >
        <Form.Item
          className={classes.formItem}
          name="email"
          label="Enter your email"
          rules={[
            {
              type: 'email',
              message: 'Invalid Email',
              whitespace: true,
            },
            {
              required: true,
              message: 'This field is required',
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<MdMail color="grey" />}
            placeholder="Email"
            size="large"
            className={classes.inputField}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className={classes.resetPasswordBtn}
            disabled={state.loading}
            type="primary"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            icon={<IoArrowBackOutline />}
            className={classes.backBtn}
            disabled={state.loading}
            href="/login"
          >
            Back To Login
          </Button>
          <p className={classes.loginText}>
            Don't have an account? &nbsp;
            <Typography.Link href={`/register`}>Sign Up</Typography.Link>
          </p>
          {state.loading && (
            <Spin indicator={antIcon} className={classes.feedback} />
          )}
        </Form.Item>
      </Form>
      {state.type === 'error' && (
        <Alert
          className={classes.feedback}
          showIcon
          message={
            Array.isArray(state.result?.message)
              ? state.result?.message.map((msg, id) => {
                  return <p key={id}>{msg}</p>;
                })
              : state.result?.message
          }
          type="error"
          closable
        />
      )}
      {state.type === 'success' && (
        <Alert
          className={classes.feedback}
          message={state.result?.message}
          showIcon
          type="success"
          closable
        />
      )}
    </div>
  );

  return (
    <Wrapper
      form={forgotPasswordForm}
      illustration={{ path: illustrationPath, name: 'forgot-password' }}
    />
  );
};

export default ForgotPassword;
