import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    borderRadius: 8,
    borderRadiusSM: 8,
    fontFamily: 'Nunito Sans'
  },
  components: {
    List: {
      colorBgBase: 'red',
      colorBgContainer: 'red'
    },
    Button: {
      colorPrimary: '#4773bf',
      colorPrimaryHover: '#5182d7',
      colorPrimaryActive: '#4773bf',
      borderRadius: 6,
      defaultHoverBorderColor: '#d9d9d9',
      defaultHoverBg: '#f6f8ff',
      defaultHoverColor: '#000000',
      defaultActiveBorderColor: '#d9d9d9',
      defaultActiveBg: '#f2f6fe',
      defaultActiveColor: '#000000'
    },
    Input: {
      activeBorderColor: '#cdd7e1',
      hoverBorderColor: '#cdd7e1',
      boxShadow: 'none',
    },
    Menu: {
      colorItemBgSelected: 'rgb(230, 237, 253)',
      itemActiveBg: 'rgb(230, 237, 253)',
      colorText: 'rgb(50, 56, 62)',
      itemSelectedColor: 'rgb(50, 56, 62)',
      itemHeight: 34,
      borderRadius: 1,
      itemBorderRadius: 6,
      groupTitleColor: 'rgb(85, 94, 104)',
      groupTitleFontSize: 12
    },
    Breadcrumb: {
      lastItemColor: '#0b6bcb'
    },
    Typography: {
      colorLink: '#0b6bcb',
      colorLinkHover: '#0b6bcb',
      fontFamily: 'Nunito Sans'
    },
    Checkbox: {
      colorPrimary: '#0b6bcb'
    },
    Table: {
      headerBg: '#fbfcfe',
      colorBgContainer: '#fbfcfe',
      footerBg: '#fbfcfe',
      borderRadius: 0,
      borderColor: 'rgba(99, 107, 116, 0.2)',
    },
    Select: {
      borderRadius: 6,
      colorPrimary: '#4773bf',
      hoverBorderColor: '#d9d9d9',
      activeBorderColor: '#d9d9d9',
      borderRadiusOuter: 0
    },
    Modal: {
      colorBgElevated: '#fbfcfe'
    },
    DatePicker: {
      borderRadius: 6,
      colorPrimary: '#4773bf',
      colorBgContainer: '#fbfcfe',
      colorBorder: '#cdd7e1',
      hoverBorderColor: '#cdd7e1',
      activeBorderColor: '#cdd7e1',
      boxShadow: 'none'
    }
  }
}

export default theme;