import { notification } from "antd";

interface INotificationProps {
  description?: string | string[];
}

const Notification = {
  success(props?: INotificationProps) {
    const description = props?.description
    ? Array.isArray(props.description) ? props.description.concat(' ') : props.description
    : '';

    notification.success({ message: 'Success!', description });
  },
  error(props?: INotificationProps) {
    const description = props?.description
    ? Array.isArray(props.description) ? props.description.concat(' ') : props.description
    : '';
    notification.error({ message: 'An error occurred!', description });
  },
  warning(props?: INotificationProps) {
    const description = props?.description
    ? Array.isArray(props.description) ? props.description.concat(' ') : props.description
    : '';
    notification.warning({ message: 'Warning!', description });
  },
  info(props?: INotificationProps) {
    const description = props?.description
    ? Array.isArray(props.description) ? props.description.concat(' ') : props.description
    : '';
    notification.info({ message: 'Info', description });
  },
  close() {
    notification.destroy();
  }
};

export default Notification;