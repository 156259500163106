/**
 * @param args The class name(s) or object(s) mapping class names to booleans
 * @returns A full class names string
 */
function classNames(...args: (string | Record<string, boolean | null | undefined> | false | null)[]): string {
  const classes = args.map(arg => {
    if (!arg) {
      return null;
    }

    if (typeof arg === 'object') {
      return Object.entries(arg).map(([key, value]) => {
        return (value ? key.split(' ') : null);
      });
    }

    return arg.split(' ');
  });

  const res = Array.from(new Set(classes.flat().flat())).filter(Boolean).join(' ');

  return res;
}

export default classNames;